import React from 'react';
import Button from '@mui/material/Button';
import { useWindowSize } from 'react-use';

import { ListItemText, MenuItem, Menu, Typography, Divider } from '@mui/material';
import Box from '@mui/material/Box';

import useAppRouter from 'src/hooks/useAppRouter';
import type { MenuItem as MenuItemType } from 'src/hooks/useUserProfileMenu';

import Icon from 'src/components/ui/Icon';
import Drawer from 'src/components/ui/Drawer';

import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent, styledTag } from 'src/utils/styled';

const StyledButton = styledComponent(Button)`
    text-transform: none;
    background: none;
    color: black;
`;

const StyledIcon = styledComponent(Icon)`
    margin-top: -0.2rem;
    margin-right: 0.5rem;
`;

const IconContainer = styledTag('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
`;

const ComponentWrapper = styledTag('div')`
    cursor: pointer;
    :focus {
        outline: none;
    }
`;

const StyledListItemText = styledComponent(ListItemText)`
    span {
        font-weight: 600;
    }
`;
interface Props {
    subMenuItems: MenuItemType[];
    label?: string;
    component?: React.ReactNode;
    isDrawerOpen: boolean;
    onCloseDrawer?: () => void;
}

const NavMenu: React.FC<Props> = ({
    subMenuItems,
    label,
    component,
    isDrawerOpen,
    onCloseDrawer = () => undefined,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const router = useAppRouter();
    const { width } = useWindowSize();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = async (value: MenuItemType) => {
        if (value.href) {
            setAnchorEl(null);
            await router.push(value.href);
        }
    };

    return (
        <div key={label}>
            {component ? (
                <ComponentWrapper role="button" tabIndex={0} onClick={handleClick}>
                    {component}
                </ComponentWrapper>
            ) : (
                <StyledButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="text"
                    onClick={handleClick}
                    size="large"
                >
                    <Typography variant="body1">
                        {label}
                        <StyledIcon name="ChevronDown" />
                    </Typography>
                </StyledButton>
            )}

            {width >= breakpoints.tabletPortrait && (
                <Menu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: -10,
                        horizontal: 60,
                    }}
                >
                    {subMenuItems.map((item: MenuItemType, index: number) =>
                        item.dividerLabel ? (
                            <Divider key={`divider-${index}`} />
                        ) : (
                            <div key={`${item.label}${index}`}>
                                <MenuItem
                                    data-target="nav-menu-button"
                                    data-target-id={`nav-menu-${item.label?.replace(/\s/g, '-').toLowerCase()}`}
                                    onClick={async () => {
                                        if (item.action) {
                                            item.action();
                                        }
                                        await handleItemClick(item);
                                    }}
                                >
                                    {item.iconName && (
                                        <IconContainer>
                                            <Icon name={item.iconName} size="1.5rem" />
                                        </IconContainer>
                                    )}
                                    <StyledListItemText primary={item.label} />
                                </MenuItem>
                            </div>
                        ),
                    )}
                </Menu>
            )}

            {width < breakpoints.tabletPortrait && (
                <Drawer isOpen={isDrawerOpen} onCloseDrawer={onCloseDrawer}>
                    {subMenuItems.map((item: MenuItemType, index: number) =>
                        item.dividerLabel ? (
                            <Divider style={{ width: '100%' }} />
                        ) : (
                            <React.Fragment key={`${item.label}${index}`}>
                                <Box
                                    p="0.8rem"
                                    display="flex"
                                    onClick={async () => {
                                        if (item.action) {
                                            item.action();
                                        }
                                        await handleItemClick(item);
                                    }}
                                >
                                    {item.iconName && (
                                        <IconContainer>
                                            <Icon name={item.iconName} size="1.5rem" />
                                        </IconContainer>
                                    )}
                                    <StyledListItemText primary={item.label} />
                                </Box>
                            </React.Fragment>
                        ),
                    )}
                </Drawer>
            )}
        </div>
    );
};

export default NavMenu;
